import React, { useState } from 'react';
import addToMailchimp from 'gatsby-plugin-mailchimp';

import SpinSVG from '../spin-svg/spin-svg.component';

const MailchimpNewsLetter = () => {
  const [email, setEmail] = useState('');
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);

  const _handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const result = await addToMailchimp(email);
    setEmail('');
    setResult(result);
    setLoading(false);
  };

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  return (
    <div className='bg-white'>
      <div className='max-w-7xl mx-auto px-4 py-6 sm:px-6 lg:py-8 lg:px-8'>
        <div className='px-6 py-6 bg-gradient-to-br from-fuchsia-500 to-purple-600 rounded-3xl shadow-lg md:py-12 md:px-12 lg:py-16 lg:px-16 xl:flex xl:items-center'>
          <div className='xl:w-0 xl:flex-1'>
            <h2 className='text-2xl font-bold tracking-tight text-white sm:text-3xl font-osans'>
              Want lectures and articles updates?
            </h2>
            <p className='mt-3 max-w-3xl text-lg leading-6 text-white font-osans'>
              Sign up for our newsletter to stay up to date.
            </p>
          </div>
          <div className='mt-8 sm:w-full sm:max-w-md xl:mt-0 xl:ml-8'>
            <form className='sm:flex' onSubmit={_handleSubmit}>
              <label htmlFor='email-address' className='sr-only'>
                Email address
              </label>
              <input
                id='email-address'
                name='email-address'
                type='email'
                autoComplete='email'
                required
                value={email}
                onChange={handleChange}
                className='w-full border-white px-5 py-3 bg-white placeholder-blue-gray-500 focus:outline-none focus:placeholder-blue-gray-400 focus:ring-1 focus:ring-purple-500 focus:border-purple-500 rounded-md font-fira'
                placeholder='Enter your email'
              />
              <button
                type='submit'
                style={{ cursor: loading ? 'not-allowed' : undefined }}
                disabled={loading}
                className='mt-3 w-full flex items-center justify-center px-5 py-3 border border-transparent shadow text-base font-medium rounded-md text-white bg-purple-900 hover:bg-purple-800 focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto sm:flex-shrink-0 font-fira'
              >
                {loading ? (
                  <SpinSVG size={'h-5 w-5'} color={'text-white'} />
                ) : (
                  'Notify me'
                )}
              </button>
            </form>
            <p className='mt-3 text-sm text-white'>{result?.msg}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MailchimpNewsLetter;
