import * as React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout/layout.component';
import DisplayPostContainer from '../components/display-post/display-post-container.component';
import DisplayPost from '../components/display-post/display-post.component';
import Seo from '../components/seo/seo.component';
import MailchimpNewsLetter from '../components/mailchimp-newsletter/mailchimp-newsletter.component';

const IndexPage = ({ data }) => {
  const { nodes: blogsNodes } = data.blogs;
  const { nodes: lecturesNodes } = data.lectures;

  return (
    <Layout>
      <Seo
        description='Const is a group passionate JavaScript and Web Development students who
        are dedicated to learn and teach JavaScript with a brand new mindset!'
      />
      <DisplayPostContainer>
        <DisplayPost
          headlineTitle='Recent Lectures'
          containerStyles='mb-20'
          headlineStyles='text-fuchsia-700'
          posts={lecturesNodes}
        />
        <DisplayPost
          headlineTitle='Recent Articles'
          headlineStyles='text-rose-600'
          posts={blogsNodes}
        />
      </DisplayPostContainer>
      <MailchimpNewsLetter />
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query ListBlogLecturesQuery {
    blogs: allMarkdownRemark(
      limit: 3
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { fileAbsolutePath: { regex: "/blogs/" } }
    ) {
      nodes {
        id
        frontmatter {
          title
          post
          date(formatString: "MMMM Do, YYYY")
          dateTime: date
          description
          author
          slug
          coverImage
        }
      }
    }
    lectures: allMarkdownRemark(
      limit: 3
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { fileAbsolutePath: { regex: "/lectures/" } }
    ) {
      nodes {
        id
        frontmatter {
          title
          post
          date(formatString: "MMMM Do, YYYY")
          dateTime: date
          description
          author
          slug
          coverImage
        }
      }
    }
  }
`;
