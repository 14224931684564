import * as React from 'react';

import Post from '../post/post.component';

const DisplayPost = ({
  headlineTitle,
  headlineDesc,
  headlineStyles,
  containerStyles,
  posts,
}) => {
  return (
    <div className={`relative max-w-7xl mx-auto ${containerStyles}`}>
      <div className='text-center'>
        <h2
          className={`text-3xl tracking-tight font-bold font-fira ${headlineStyles} sm:text-4xl`}
        >
          {headlineTitle}
        </h2>
        <p className='mt-3 max-w-2xl mx-auto text-xl font-osans text-blue-gray-500 sm:mt-4'>
          {headlineDesc}
        </p>
      </div>
      <div className='mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none'>
        {posts.map((post) => (
          <Post
            key={post.id}
            postTitle={post.frontmatter.title}
            postDescription={post.frontmatter.description}
            postType={post.frontmatter.post}
            postCoverImageUrl={post.frontmatter.coverImage}
            postLink={
              post.frontmatter.post === 'lecture'
                ? `/lectures/${post.frontmatter.slug}`
                : `/blogs/${post.frontmatter.slug}`
            }
            postCreationDateTime={post.frontmatter.dateTime}
            postCreationDate={post.frontmatter.date}
            authorNames={post.frontmatter.author}
          />
        ))}
      </div>
    </div>
  );
};

export default DisplayPost;
