import * as React from 'react';

const DisplayPostContainer = ({ children }) => {
  return (
    <div className='relative pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8'>
      <div className='absolute inset-0'>
        <div className='bg-white h-1/3 sm:h-2/3' />
      </div>
      {children}
    </div>
  );
};

export default DisplayPostContainer;
