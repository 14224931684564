import React from 'react';
import { Link } from 'gatsby';
import { Transformation, Image } from 'cloudinary-react';

const Post = ({
  postTitle,
  postDescription,
  postType,
  postLink,
  postCoverImageUrl,
  postCreationDateTime,
  postCreationDate,
  authorImageUrl,
  authorNames,
}) => {
  return (
    <div className='flex flex-col rounded-lg shadow-lg overflow-hidden'>
      <div className='flex-shrink-0'>
        <Image
          className='h-48 w-full object-cover'
          alt={postTitle}
          cloudName='dmcookpro'
          publicId={
            postCoverImageUrl ||
            (postType === 'lecture'
              ? 'const/lectures/lectures-cover'
              : 'const/blogs/blogs-cover')
          }
          loading='lazy'
          width='100%'
          height='100%'
        ></Image>
      </div>
      <div className='flex-1 bg-white p-6 flex flex-col justify-between'>
        <div className='flex-1'>
          <p
            className={`text-xs font-medium font-fira ${
              postType === 'lecture' ? 'text-fuchsia-700' : 'text-rose-600'
            } uppercase tracking-widest`}
          >
            {postType}
          </p>
          <Link to={postLink} className='block mt-2'>
            <p className='text-xl font-semibold font-fira text-blue-gray-800'>
              {postTitle}
            </p>
            <p className='mt-3 font-osans text-base text-blue-gray-500'>
              {postDescription}
            </p>
          </Link>
        </div>
        <div className='mt-6 flex items-center'>
          <div className='flex-shrink-0'>
            <span className='sr-only'>{authorNames}</span>
            <Image
              className='h-10 w-10 rounded-full object-cover'
              cloudName='dmcookpro'
              publicId={
                authorImageUrl ||
                `const/authors/authors-${authorNames
                  .split(' ')[0]
                  .toLowerCase()}`
              }
              loading='lazy'
              width='100%'
              height='100%'
            >
              <Transformation rawTransformation='w_1000,ar_16:9,c_fill,g_auto,e_sharpen' />
            </Image>
          </div>
          <div className='ml-3'>
            <p className='text-sm font-medium font-fira text-blue-gray-800'>
              {authorNames}
            </p>
            <div className='flex space-x-1 text-sm font-osans text-blue-gray-500'>
              <time dateTime={postCreationDateTime}>{postCreationDate}</time>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Post;
